import styled from 'styled-components';
import React from 'react';
import ReactCarousel from '../components/ReactCarousel';
import ProjectTitle from '../components/ProjectTitle';
import {
    casper,
    gro,
    dasy,
    raytracer,
    opengl,
    gbm,
    omlt,
    nlp,
    v3d,
} from '../slides_json/data.js';

const Projects = styled.div`
	margin-bottom: 10vh;
    margin-left: 16.5vw;
    color: #d0d0d0;
`;

const A = styled.a`
    color: #44B282;
    transition: all 0.25s ease;
    width: 10%;
    line-height: 35px;
    :hover {
        cursor: pointer;
        color: #ffffff;
        text-decoration: none;
    }
`;

const LI = styled.li`
    color: #a0a0a0;
`;

const Section = styled.div`
    width: 75%;
`

function ProjectPage(props) {
    return(
        <Projects>

            <h2>Software Engineering for Web</h2>
            <Section>
            <ul>
                <li><A href="https://www.appliedintuition.com/">Applied Intuition</A> - Software Engineer, Mountain View CA, April 2023 - Present</li>
                <p>Applied Intuition provides software infrastructure to safely develop, test, and deploy autonomous vehicles at scale.</p>
                <li><A href="https://www.variant3d.io/">Variant 3D</A> - Software Engineer R&D, August 2021 - April 2023</li>
                <p>R&D Engineer of geometric processing / real-time rendering systems for 3D knitting CAD & machine interfacing knit software.</p>
                <p> - I co-authored two technical patents on novel geometry processing techniques during this time.</p>
                <li><A href="https://github.com/SpencerSherk/sombra-marketplace">Smart contract code for Sombra marketplace</A> - Contract, New York NY, August 2021</li>
                <p>Smart contract code deployed for sombra NFT marketplace, receiving a successful audit from Hashex (industry leading security auditor)</p>
                <p>- Deployed on the Binance Smart Chain where it has been succesfully interacted with by thousands of users.</p>
                <li><A href="https://www.casper.com/">Casper</A> - New York NY, Intern SWE, June 2019 - Aug 2019, Associate SWE, May 2021 - Jun 2021, Software Engineer, June 2021 - July 2021</li>
                <p>Full Stack Engineer, working on a high traffic, large scale e-commerce system with a playful site experience.</p>
                <p>- Frontend site infrastructure, NodeJS Build tooling, API middleware, UI/UX for Casper.com.</p>
                <p>- In browser 3D product customizer with React-Three-Fiber scene connected to product catalogue JAPI endpoint.</p>
                <li><A href="https://www.commoncheck.org/">COVIDcheck (now Common Check) - The Commons Project</A> - Software Engineer (Contract), New York NY, March 2020 - May 2020</li>
                <p>Developed Interactive Risk Assessment and health data tracking for COVIDcheck: A digital service that verifies the health status of incoming travelers before they begin their journey.</p>
                <p>- Went from creating the first repository, to serving a live service beta in less than a month. (GCP, Gatsby, ReactJS)</p>
                <li><A href="https://github.com/SpencerSherk/WebDevRnD">Web Technology Experiments</A> - Personal Projects, 2018</li>
                <p>Collection of personal projects experimenting with web technologies including: a from-scratch toy web framework based on Express, an AJAX app for keyboard mashing, a shared ASCII sketchbook, and more.</p>
                <li><A href="https://github.com/SpencerSherk/ohw18_omlet">Ocean Machine Learning Toolkit</A> - Ocean Hack Week Conference, University of Washington, 2018</li>
                <p>Toolkit for flagging, and correcting anomolies in data collected by the Ocean Observatory Institute's fleet of research boueys. Created during the Ocean Sciences summer conference at University of Washington Conference.</p>
                <li><A href="https://github.com/SpencerSherk/Raytracer">Raytracer</A> - Personal Project, 2018</li>
                <p>C++ Raytracer made from scratch, using a bottom-up hierarchical bounding box tree acceleration structure.</p>
                <li><A href="https://gro-intelligence.com/">Gro-Intelligence</A> - Software Engineering and Design Intern, June 2017 - Aug 2017</li>
                <p>Designed and developed interactive data visualizations for Gro's web app. Gro is the industry-leading software company for predicitive agricultural insights and analytics.</p>
                <p>- Focused on using Mapbox GL JS, to render interactive WebGL map graphics demonstrating how Gro’s ML models predict crop yields using satellite image training data.</p>
            </ul>
            </Section>
            <h2>Game Development / 3D Animation / Tech Art</h2>
            <Section>
            <ul>
                <li><A href="https://www.youtube.com/watch?v=KLmkQpeZGBk">Rent</A> - Music Video for <A href="https://open.spotify.com/artist/3bSZmhmZ0bWMkMl37DK5ap">Halima</A>, 2022</li>
                <p>Role: Fluid simulation made using Houdini, procedural thin film shader using Redshift nodes.</p>
                <li><A href="https://eternal.tv/programs/fleshworks?cid=2523070&permalink=dice">Dice</A> - Short Film Directed / Animated, Score by <A href="https://open.spotify.com/artist/5sHn9ENA6XtnLmOrVQt2BB">Beshken</A>, 2021</li>
                <p>An experiment in mixing traditional 3D skeletal rig animation with physics-based mesh simulation. Premiered at <A href="https://www.youtube.com/watch?v=tq5hwrlkNug&ab_channel=EternalFamily">Fleshworks</A>, now streaming on <A href="https://eternal.tv/programs/fleshworks?cid=2523070&permalink=dice">Eternal.tv</A></p>
                <p>- Award Winning Entry in Animation Block's 2022 Animation Block Party Film Festival</p>
                <li><A href="https://www.youtube.com/watch?v=gvLVX25-Go4">2.02 Killer Year</A> - Music Video for <A href="https://open.spotify.com/artist/6XYvaoDGE0VmRt83Jss9Sn">King Gizzard and the Lizard Wizard</A>, 2021</li>
                <p>Role: Blender Python scripting to drive vertex animation textures for butterflies, allowing animated meshes to be used within a UE4 niagara particle system.</p>
                <li><A href="https://www.youtube.com/watch?v=p5x7yBYDu5M">Party Like A Human</A> - Music Video for <A href="https://open.spotify.com/artist/5ly9gfnncBXv77g2zuRG9m">General Elektriks</A>, 2021</li>
                <p>Role: Blood-filled Chalice fluid simulation using Houdini. Imported alembic cached sims into UE4 for real-time playback.</p>
                <li><A href="https://www.youtube.com/watch?v=osCbN33BWD0">DOMO Gets a Tattoo</A> - Adult Swim TV Smalls, Aired 2021</li>
                <p>Role: VDB Smoke Simulations used for DOMO's body, and animated cloudscape made with Houdini.</p>
                <li><A href="https://www.papermag.com/dsychira-xdream-deluxe-2646424599.html?rebelltitem=19#rebelltitem19">Album Art for Xdream Remix</A>, and accompanying <A href="https://www.instagram.com/p/CDFQqjTjrdm/">Animation</A> - for <A href="https://open.spotify.com/artist/1X1US09KRu50JVXxnbofgX">Dasychira</A>, 2020</li>
                <p>Role: Rendering / texturing / lighting of pre-existing model. Tearing animation physics simulated using Houdini.</p>
                <li><A href="https://www.shortoftheweek.com/2021/01/22/goodbye-mommy/">Goodbye Mommy</A> - Short film made with Jack Wedge, 2019</li>
                <p>Role: 3D VFX with Houdini: Plane flying & Explosion, Title which forms from smoke above crashsite, Cloudscape, Planet expanding during death sequence.</p>
                <li><A href="http://dasychira.com/plush.html">Xdre.am Web Experience</A> - for <A href="https://open.spotify.com/artist/1X1US09KRu50JVXxnbofgX">Dasychira</A>, 2019</li>
                <p>Role: Website Development of interactive 3D scene using ThreeJS + GLSL.</p>
                <li><A href="https://www.youtube.com/watch?v=7eICrGKt5IY">Pyro Simulation Tests </A> - Personal Project / Experiment, 2018</li>
                <p>Created mostly using gas solvers with vex script in Houdini.</p>
                <li><A href="https://www.youtube.com/watch?v=tOlMgL2bO_4">Bill Flaps His Arms</A> - NYU ITP, 2018</li>
                <p>Documentation of animated VR experience, created entirely in VR with gesture stroke keyframes in Quill, ported to steam VR kit.</p>
                <li><A href="https://www.youtube.com/watch?v=fvAfLgVvvLY">Virtual Portrait of Pollution</A> - NYU ITP, 2018</li>
                <p>Unity Game / Data Visualization, with water undulation shader and tween path guided C# particle simulation.</p>
            </ul>
            </Section>
            <h2>Education</h2>
            <Section>
            <ul>
            <li>Bachelors Degree in Computer Science from NYU, including graduate coursework in VR development at NYU's ITP program, December 2019</li>
            </ul>
            </Section>
        </Projects>
    );
}

// old one
// function ProjectPage(props) {
//     return(
//         <Projects>
//             <ProjectTitle first={true} projName={'Casper'} />
//             <ReactCarousel projInfo={casper}/>
//             <ProjectTitle projName={'Gro-Intelligence'} />
//             <ReactCarousel projInfo={gro}/>
//             <ProjectTitle projName={'Dasychira.com'} />
//             <ReactCarousel projInfo={dasy}/>
//             <ProjectTitle projName={'Pure C++ Raytracer'} />
//             <ReactCarousel projInfo={raytracer}/>
//             <ProjectTitle projName={'OpenGL 2D/3D Rasterizer'} />
//             <ReactCarousel projInfo={opengl}/>
//             <ProjectTitle projName={'GoodBye Mommy'} />
//             <ReactCarousel projInfo={gbm}/>
//             <ProjectTitle projName={'Ocean Machine Learning Toolkit'} />
//             <ReactCarousel projInfo={omlt}/>
//             <ProjectTitle projName={'Multi-Target Sentiment Analysis'} />
//             <ReactCarousel projInfo={nlp}/>
//             <ProjectTitle projName={'Various 3D Projects'} />
//             <ReactCarousel last={true} projInfo={v3d}/>
//         </Projects>
//     );
// }




export default ProjectPage;



