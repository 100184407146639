import styled from 'styled-components';

export const SectionLine = styled.hr`
    color: white;
    height: 1px;
    border: none;
`;

            // <hr style={{height: "1px", backgroundColor: "white", border: "none", margin: "20px 0"}} />


export const SectionDesc = styled.div`
    text-align: left;
    margin-left: 16.4vw;
    margin-top: 4.6vh;
    float:left;
    @media (max-width: 800px) {
        display:none;
    }
`;

export const MobileTitle = styled.h1`
    text-align: left;
    margin-left: 16.4vw;
    margin-top: 4.6vh;
    display: none;
    @media (max-width: 800px) {
        display: none;
    }
`;

export const SectionTitle = styled.h1`
    color: #ffffff;
    text-align: right;
    margin-top: 5vh;
    margin-right: 16.2vw;
    top: 20px;
    @media (max-width: 800px) {
        display: block;
        text-align: left;
        margin-left: 16.4vw;
    }
`;
