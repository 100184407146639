import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-scroll';
import ProjectPage from './ProjectPage';
import AboutPage from './AboutPage';
import Hero from '../components/Hero';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Fade from 'react-reveal/Reveal';
import ThreeScene from '../components/ThreeScene';

import {
    FullPage,
    Nav,
    ArrowDown,
    IntroRow,
    IntroText,
    NavRow,
    ArrowRow,
    MailTo,
} from './css/HomePage.css';


function HomePage(props) {

    const [revealNav, setRevealNav] = useState(0);

    const [currentShape, setCurrentShape] = useState('default');

    // Function to call when a link is hovered
    const handleLinkHover = (shape) => {
        setCurrentShape(shape);
    };

    const linkProps = {
        activeClass: "active",
        spy: true,
        smooth: true,
        offset: 14,
        duration: 500,
    }

    return(
        <div>
            <FullPage>
                <div style={{ flex: '1 1 40%' , width: '40%'}}>
                    <Jumbotron className="bg-transparent jumbotron-fluid">
                        <Container fluid>
                            <IntroRow className="justify-content-left">
                                <Col md={6} xs={12}>
                                    <Fade ssrFadeout wait={800} onReveal={() => setRevealNav(!revealNav)}>
                                        {props.title && <IntroText>{props.title}</IntroText>}
                                    </Fade>
                                </Col>
                            </IntroRow>
                            <NavRow className="justify-content-left">
                                <Col md={6} xs={12}>
                                    <Fade right cascade ssrFadeout wait={1600}>
                                        <div>
                                            <div>{props.projects && <Link {...linkProps} to="projLink" duration={500}>
                                                <Nav onMouseEnter={() => handleLinkHover('tri')}>{props.projects}</Nav></Link>}
                                            </div>
                                            <div>{props.about && <Link {...linkProps} to="aboutLink" duration={1200}>
                                                <Nav onMouseEnter={() => handleLinkHover('cube')}>{props.about}</Nav></Link>}
                                            </div>
                                            <div>{props.contact && <Nav onMouseEnter={() => handleLinkHover('sphere')}>
                                                <MailTo href="mailto:spencer@spencersherk.com">{props.contact}</MailTo></Nav>}
                                            </div>
                                        </div>
                                    </Fade>
                                </Col>
                            </NavRow>
                        </Container>
                    </Jumbotron>
                </div>
                <div style={{ flex: '1 1 50%', height: '100vh', width: '50%'}}>
                    <ThreeScene currentShape={currentShape}/>
                </div>
                <div style={{ flex: '1 1 10%', height: '100vh', width: '10%'}} /> 
            </FullPage>
            <div id="projLink">
                <Hero title={props.projects} desc={props.projDesc}/>
                <ProjectPage />
            </div>
            <div id="aboutLink">
                <Hero title={props.about} desc={props.aboutDesc}/>
                <AboutPage />
            </div>
        </div>
    );
}

export default HomePage;