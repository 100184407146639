import React from 'react';
import Content from '../components/Content';
import styled from 'styled-components';

let Bio = styled.div`
    color: #e0e0e0;
    margin-left: 15.5vw;
    font-size: 18px;
    @media (max-width: 800px) {
       font-size: 13px; 
    }
    @media (max-width: 330px) {
       font-size: 12px; 
    }
`

let Page = styled.div`
    display: flex;
    margin-top: 20vh;
    height: 65vh;
    @media (max-width: 800px) {
        margin-top: 5vh;
        margin-right: 22vw;
    }
`;

let Link = styled.a`
    transition: all 0.5s ease;
    color: #44B282;
    :hover {
        color: #FF8A76;
        text-decoration: none;
    }
`;

const A = styled.a`
    color: #44B282;
    transition: all 0.25s ease;
    width: 10%;
    line-height: 35px;
    :hover {
        cursor: pointer;
        color: #ffffff;
        text-decoration: none;
    }
`;

function AboutPage(props) {
    return(
        <Page>
            <div style={{ flex: '1 1 50%', width: '50%'}}>
                <Bio>            
                    <p>
                    Spencer Sherk's professional background is a mix between building web applications, and 3D graphics programming. He specializes in shader programming, and leverages the GPU to bring real-time graphics to the web, and other resource-limited contexts. He holds a Bachelors in Computer Science from NYU, and during his undergrad also completed graduate level coursework in VR development at NYU's ITP graduate program.
                    </p>
                    <p>
                    Spencer also works as a technical artist; he uses code, game engines, and procedural software to create 3D assets & animations used in Games, TV, and Film. Past projects include work for Adult Swim, and major musical artists such as Halima, King Gizzard and the Lizard Wizard, General Elektriks, Beshken, Dasychira, and more.
                    </p>
                </Bio>
            </div>
            <div style={{ flex: '1 1 50%', width: '50%', padding: "5%", paddingLeft: "20%",}}>
                <p><A href="https://github.com/SpencerSherk">Github</A></p>
                <p><A href="https://www.linkedin.com/in/spencer-sherk-2aa04a174">Linkedin</A></p>
                <p><A href="https://www.are.na/spencer-sherk-mj86ldsfilc">Are.na</A></p>
                <p><A href="https://www.shadertoy.com/user/spsherk_">Shadertoy</A></p>
            </div>
        </Page>
    );

}

export default AboutPage;