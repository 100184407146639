import React, { useRef, useEffect, useState } from 'react';
import * as THREE from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { AfterimagePass } from 'three/examples/jsm/postprocessing/AfterimagePass.js';

function generateSierpinskiTriangle(depth, vertices, offsetX = 0, offsetY = 0, scale = 1) {
    if (depth === 0) {
        vertices.push(
            offsetX, offsetY, 0,
            offsetX + scale / 2, offsetY + Math.sqrt(3) / 2 * scale, 0,
            offsetX + scale, offsetY, 0,
            offsetX, offsetY, 0, // Close the triangle
        );
    } else {
        const newScale = scale / 2;
        generateSierpinskiTriangle(depth - 1, vertices, offsetX, offsetY, newScale);
        generateSierpinskiTriangle(depth - 1, vertices, offsetX + newScale, offsetY, newScale);
        generateSierpinskiTriangle(depth - 1, vertices, offsetX + newScale / 2,
                                    offsetY + Math.sqrt(3) / 2 * newScale, newScale);
    }
}

const ThreeScene = ({ currentShape }) => {
  const mountRef = useRef(null);
  // const [currentShape, setCurrentShape] = useState('sphere');
  const rotationVelocity = useRef({ x: 0, y: 0 });
  const lastMousePosition = useRef({ x: window.innerWidth / 2, y: window.innerHeight / 2 });
  const sphereGeometryRef = useRef();   // Store sphere geometry
  const cubeGeometryRef = useRef();     // Store cube geometry
  const triGeometryRef = useRef();      // Store tri geometry
  const particleSystemRef = useRef();   // Reference to the particle system
  const composerRef = useRef();         // Reference to the EffectComposer

  useEffect(() => {
    const scene = new THREE.Scene();

    // Assuming your canvas or renderer div is half the window's width
    const aspectRatio = (window.innerWidth / 2) / window.innerHeight;
    const camera = new THREE.PerspectiveCamera(75, aspectRatio, 0.1, 1000);
    camera.position.z = 5;

    // Make sure to update this on window resize as well
    window.addEventListener('resize', () => {
        camera.aspect = (window.innerWidth / 2) / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth / 2, window.innerHeight);
    });

    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth / 2, window.innerHeight);
    renderer.setClearColor(0x000000, 0);
    mountRef.current.appendChild(renderer.domElement);

    // Define and store sphere & box geometries
    const sphereGeometry = new THREE.SphereGeometry(1.2, 31, 31);
    const cubeGeometry = new THREE.BoxGeometry(2, 2, 2, 5, 5, 5).toNonIndexed();
    
    // Create geometry and material for the Sierpinski Triangle
    const vertices = [];
    const scale = 2;
    const offsetX = -scale / 2;                     // Center x-axis
    const offsetY = -(Math.sqrt(3) / 4) * scale;    // Center y-axis
    generateSierpinskiTriangle(5 /* depth */, vertices, offsetX, offsetY, scale);
    const triGeometry = new THREE.BufferGeometry();
    triGeometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));

    sphereGeometryRef.current = sphereGeometry;
    cubeGeometryRef.current = cubeGeometry;
    triGeometryRef.current = triGeometry;

    const maxVertices = Math.max(
      sphereGeometry.getAttribute('position').count,
      cubeGeometry.getAttribute('position').count,
      triGeometry.getAttribute('position').count
    );
    let mergedGeometry = new THREE.BufferGeometry();
    mergedGeometry.copy(sphereGeometry); // Start with sphere geometry

    // Store particle colors in buffer geo for later
    const numVertices = mergedGeometry.getAttribute('position').count;
    const colors = new Float32Array(numVertices * 3); // 3 components per vertex

    // Initialize all vertices as white
    for (let i = 0; i < numVertices; i++) {
        colors[i * 3] = 1;
        colors[i * 3 + 1] = 1;
        colors[i * 3 + 2] = 1;
    }
    mergedGeometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));

    // Initialize particle system
    const particleMaterial = new THREE.PointsMaterial({
        size: 0.03,
        vertexColors: true,
    });
    const particleSystem = new THREE.Points(mergedGeometry, particleMaterial);
    particleSystemRef.current = particleSystem;
    scene.add(particleSystem);

    // Setup post-processing chain (visual trails)
    const renderPass = new RenderPass(scene, camera);
    const afterimagePass = new AfterimagePass();
    afterimagePass.uniforms['damp'].value = 0.9;

    const composer = new EffectComposer(renderer);
    composer.addPass(renderPass);
    composer.addPass(afterimagePass);
    composerRef.current = composer;

    const handleMouseMove = (event) => {
      const deltaX = event.clientX - lastMousePosition.current.x;
      const deltaY = event.clientY - lastMousePosition.current.y;
      rotationVelocity.current = { x: deltaY * 0.001, y: deltaX * 0.001 };

      lastMousePosition.current.x = event.clientX;
      lastMousePosition.current.y = event.clientY;
    };

    window.addEventListener('mousemove', handleMouseMove);

    const animate = () => {
      requestAnimationFrame(animate);
      composer.render(); // Use the composer for rendering
      // renderer.render(scene,camera); // Use the normal renderer (no trails)

      // Apply rotation based on mouse movement
      particleSystem.rotation.x += rotationVelocity.current.x;
      particleSystem.rotation.y += rotationVelocity.current.y;

      // Dampen the rotation over time
      rotationVelocity.current.x *= 0.97;
      rotationVelocity.current.y *= 0.97;

      // Keep basic idle rotation
      if (rotationVelocity.current.y < 0.002) {
        rotationVelocity.current.y += 0.0002;
      } else {
        // Dampen the rotation over time
        rotationVelocity.current.x *= 0.97;
        rotationVelocity.current.y *= 0.97;
      }

    };
    animate();

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      mountRef.current.removeChild(renderer.domElement);
    };
  }, []);

  useEffect(() => {
    if (!particleSystemRef.current) return;
    const targetGeometry = 
      currentShape === 'cube' ? cubeGeometryRef.current :
      currentShape === 'tri' ? triGeometryRef.current : 
      sphereGeometryRef.current;
    morphToShape(targetGeometry, particleSystemRef.current);
  }, [currentShape]);

  const morphToShape = (targetGeometry, particleSystem) => {
    const initialPositions = particleSystem.geometry.getAttribute('position').array;
    const targetPositions = targetGeometry.getAttribute('position').array;
    const colors = particleSystem.geometry.getAttribute('color').array; // Get the colors array
    const steps = 100;
    let currentStep = 0;

    // Define initial and target rotations
    const initialRotation = { x: particleSystem.rotation.x, y: particleSystem.rotation.y, z: particleSystem.rotation.z };
    const targetRotation = { x: 0, y: 0, z: 0 }; // Desired final rotation

    // Initialize target colors based on targetPositions length
    const targetColors = new Float32Array(colors.length);
    for (let i = 0; i < targetColors.length; i += 3) {
        if (i < targetPositions.length) {
            targetColors[i] = 1; // Keep white color for active vertices
            targetColors[i + 1] = 1;
            targetColors[i + 2] = 1;
        } else {
            targetColors[i] = 0; // Set inactive vertices to black
            targetColors[i + 1] = 0;
            targetColors[i + 2] = 0;
        }
    }

    const morph = () => {
      if (currentStep <= steps) {
        const progress = easeInOutCubic(currentStep / steps);
        for (let i = 0; i < initialPositions.length; i += 3) {
          // Check if this vertex is within the active range
          if (i < targetPositions.length) {
            // Update positions
            initialPositions[i] = initialPositions[i] + (targetPositions[i] - initialPositions[i]) * progress;
            initialPositions[i + 1] = initialPositions[i + 1] + (targetPositions[i + 1] - initialPositions[i + 1]) * progress;
            initialPositions[i + 2] = initialPositions[i + 2] + (targetPositions[i + 2] - initialPositions[i + 2]) * progress;

            // Gradually change colors towards the target state
            colors[i] = THREE.MathUtils.lerp(colors[i], targetColors[i], progress);
            colors[i + 1] = THREE.MathUtils.lerp(colors[i + 1], targetColors[i + 1], progress);
            colors[i + 2] = THREE.MathUtils.lerp(colors[i + 2], targetColors[i + 2], progress);
          } else {
            // For vertices beyond the target range, set the color to black (inactive state)
            colors[i] = 0; // Red component
            colors[i + 1] = 0; // Green component
            colors[i + 2] = 0; // Blue component
          }
        }

        particleSystem.geometry.attributes.position.needsUpdate = true;
        particleSystem.geometry.attributes.color.needsUpdate = true;

        // Interpolate rotation
        particleSystem.rotation.x = initialRotation.x + (targetRotation.x - initialRotation.x) * progress;
        particleSystem.rotation.y = initialRotation.y + (targetRotation.y - initialRotation.y) * progress;
        particleSystem.rotation.z = initialRotation.z + (targetRotation.z - initialRotation.z) * progress;

        currentStep++;
        requestAnimationFrame(morph);
      }
    };
    morph();

  };

  const easeInOutCubic = (t) => {
    return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
  };

  return (
    <div>
      <div ref={mountRef} style={{ height: '100%' }}></div>
    </div>
  );
};

export default ThreeScene;