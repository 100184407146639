import React from 'react';
import {
    SectionLine,
    SectionDesc,
    MobileTitle,
    SectionTitle,
} from './css/Hero.css.js';

function Hero(props) {

    return(
        <div>
            <hr style={{height: "1px", backgroundColor: "#1f1f1f", border: "none", margin: "20px 0"}} />
            <MobileTitle>
                <p>- {props.title} -</p>
            </MobileTitle>
            <SectionDesc>
                <p>{props.desc}</p>
            </SectionDesc>
            <SectionTitle>
                <p>- {props.title} -</p>
            </SectionTitle>
        </div>
    );

}


export default Hero;